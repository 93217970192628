import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const Rating = styled.div`
  ${tw`max-w-screen-2xl mx-auto flex flex-col items-center px-6 py-10`}
`

const Headline = styled.div`
  ${tw`font-bold text-2xl text-center`}
`

const Stars = styled.div`
  ${tw`text-black mb-9`}
`

const Votes = styled.div`
  ${tw`text-black`}
`

const WpAcfRatingModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfBlockRatingModule

  return (
    <Rating>
      <Headline>Na, wie nice war der Artikel?</Headline>
      <Stars>
        <svg width="254" height="35" viewBox="0 0 254 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_993_15167)">
            <path d="M18.2747 26.7385L6.99914 35L11.3076 21.6307L0.0319824 13.3693H13.9701L18.2747 0L22.5832 13.3693H36.5213L25.2457 21.6307L29.5503 35L18.2747 26.7385Z" fill="#0000FF" />
          </g>
          <g clipPath="url(#clip1_993_15167)">
            <path d="M72.6363 26.7385L61.3607 35L65.6692 21.6307L54.3936 13.3693H68.3317L72.6363 0L76.9448 13.3693H90.8829L79.6073 21.6307L83.9119 35L72.6363 26.7385Z" fill="#0000FF" />
          </g>
          <g clipPath="url(#clip2_993_15167)">
            <path d="M126.998 26.7385L115.723 35L120.031 21.6307L108.755 13.3693H122.694L126.998 0L131.307 13.3693H145.245L133.969 21.6307L138.274 35L126.998 26.7385Z" fill="#0000FF" />
          </g>
          <g clipPath="url(#clip3_993_15167)">
            <path d="M181.36 26.7385L170.084 35L174.393 21.6307L163.117 13.3693H177.055L181.36 0L185.668 13.3693H199.606L188.331 21.6307L192.635 35L181.36 26.7385Z" fill="#0000FF" />
          </g>
          <g clipPath="url(#clip4_993_15167)">
            <mask id="mask0_993_15167" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="217" y="0" width="37" height="35">
              <path
                d="M236.602 25.5371L235.722 24.8922L234.841 25.5371L227.273 31.0821L230.172 22.0876L230.502 21.0646L229.635 20.4293L222.031 14.8586H231.417H232.502L232.835 13.8257L235.722 4.85757L238.612 13.8261L238.945 14.8586H240.03H249.415L241.812 20.4293L240.946 21.0644L241.275 22.0872L244.171 31.0833L236.602 25.5371Z"
                fill="#BAD066"
                stroke="black"
                strokeWidth="2.97872"
              />
            </mask>
            <g mask="url(#mask0_993_15167)">
              <rect x="190.67" y="-0.744629" width="49.1489" height="35.7447" fill="#0000FF" />
            </g>
            <path
              d="M235.942 26.4382L235.722 26.2769L235.501 26.4382L225.153 34.0205L229.109 21.7449L229.191 21.4892L228.974 21.3304L218.617 13.7416H231.417H231.688L231.771 13.4834L235.722 1.21439L239.676 13.4835L239.759 13.7416H240.03H252.83L242.472 21.3304L242.256 21.4892L242.338 21.7449L246.291 34.0208L235.942 26.4382Z"
              stroke="#0000FF"
              strokeWidth="0.744681"
            />
          </g>
          <defs>
            <clipPath id="clip0_993_15167">
              <rect width="36.4894" height="35" fill="white" transform="translate(0.0319824)" />
            </clipPath>
            <clipPath id="clip1_993_15167">
              <rect width="36.4894" height="35" fill="white" transform="translate(54.3936)" />
            </clipPath>
            <clipPath id="clip2_993_15167">
              <rect width="36.4894" height="35" fill="white" transform="translate(108.755)" />
            </clipPath>
            <clipPath id="clip3_993_15167">
              <rect width="36.4894" height="35" fill="white" transform="translate(163.117)" />
            </clipPath>
            <clipPath id="clip4_993_15167">
              <rect width="36.4894" height="35" fill="white" transform="translate(217.479)" />
            </clipPath>
          </defs>
        </svg>
      </Stars>
      <Votes>4.6 / 5 - (9 Votes)</Votes>
    </Rating>
  )
}

WpAcfRatingModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfRatingModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfRatingModuleBlock
