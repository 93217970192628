import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const TextContainer = styled.div`
  ${tw`max-w-screen-lg p-6 mt-4`}
`

const Headline = styled.div`
  ${tw`text-4xl mb-4`}
`

const Text = styled.div`
  ${tw`font-medium leading-[1.5] text-sm`}

  h3 {
    ${tw`font-black leading-[1.5] text-4xl mb-4`}
  }

  p {
    ${tw`mb-4`}

    &:last-child {
      ${tw`mb-0`}
    }
  }

  ul {
    ${tw`mb-14`}
  }

  li {
    ${tw`relative mb-4 pl-5`}

    &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check-circle' class='svg-inline--fa fa-check-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2315aabf' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>");
    }
  }
`

const WpAcfTextFullModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfBlockTextFullModule
  return (
    <TextContainer style={{backgroundColor: blockData.backgroundColor, color: blockData.textColor}}>
      {blockData.headline && <Headline>{parse(blockData.headline)}</Headline>}
      <Text>{parse(blockData.description)}</Text>
    </TextContainer>
  )
}

WpAcfTextFullModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfTextFullModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfTextFullModuleBlock
