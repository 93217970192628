import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import Button from "@elements/button"

const Banner = styled.div`
  ${tw`bg-[#0000FF]  mt-12 md:mt-16 2xl:mt-24`}
`
const BannerWrapper = styled.div`
  ${tw`max-w-screen-2xl mx-auto px-6 py-10 flex flex-col gap-10 md:gap-0 md:flex-row`}
`
const Headline = styled.div`
  ${tw`font-bold text-4xl text-white mb-4`}
`
const Text = styled.div`
  ${tw`text-white text-lg`}

  li {
    ${tw`relative mb-[6px] pl-8`}

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_976_14318)'%3E%3Cpath d='M9.99965 0.5L11.3922 2.72253L13.4729 1.10317L14.0097 3.66072L16.5268 2.83954L16.1427 5.42356L18.7942 5.50017L17.5353 7.79932L20 8.76328L18.019 10.5003L20 12.2367L17.5353 13.2014L18.7942 15.4998L16.1427 15.5764L16.5268 18.1605L14.0097 17.3393L13.4729 19.8968L11.3922 18.2775L9.99965 20.5L8.6078 18.2775L6.5271 19.8968L5.99031 17.3393L3.47325 18.1605L3.85728 15.5764L1.20584 15.4998L2.46473 13.2014L0 12.2367L1.98098 10.5003L0 8.76328L2.46473 7.79932L1.20584 5.50017L3.85728 5.42356L3.47325 2.83954L5.99031 3.66072L6.5271 1.10317L8.6078 2.72253L9.99965 0.5Z' fill='%23FF00A1'/%3E%3C/g%3E%3Cpath d='M20.2363 4.05971L20.4702 3.82459L20.2363 3.58948L18.7939 2.13989L18.5576 1.90242L18.3213 2.13989L9.00448 11.5033L6.67862 9.16582L6.44232 8.92834L6.20603 9.16583L4.7637 10.6155L4.52977 10.8506L4.76371 11.0857L8.76825 15.1101L9.00454 15.3476L9.24082 15.1101L20.2363 4.05971Z' fill='%230000FF' stroke='%23FF00A1' stroke-width='0.666667'/%3E%3Cdefs%3E%3CclipPath id='clip0_976_14318'%3E%3Crect width='20' height='20' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
`
const BannerContent = styled.div`
  ${tw`md:w-1/2`}
`
const BannerLink = styled.div`
  ${tw`md:w-1/2 flex items-center justify-center`}
`
const WpAcfBannerModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfBlockBannerModule
  return (
    <Banner>
      <BannerWrapper>
        <BannerContent>
          <Headline>{parse(blockData.headline)}</Headline>
          <Text>{parse(blockData.text)}</Text>
        </BannerContent>
        <BannerLink>
          <Button
          buttonText="Hier gehts zu weiteren Cases"
          buttonHref="/cases/"
          buttonClasses="bg-yellow text-black font-bold uppercase text-14 2xl:text-base font-primary w-auto block py-4 px-4 md:px-2 lg:px-4 text-center"
          buttonStyle={{
            boxShadow: "3px 3px 1px #000",
          }}
        />
        </BannerLink>
      </BannerWrapper>
    </Banner>
  )
}

WpAcfBannerModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfBannerModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfBannerModuleBlock
