import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const TextContainer = styled.div`
  ${tw`max-w-screen-lg`}
`

const Text = styled.div`
  ${tw`font-medium leading-[1.5] text-sm`}

  h3 {
    ${tw`font-black leading-[1.5] text-2xl xs:text-4xl mb-4`}
  }

  p {
    ${tw`mb-4`}
  }

  ul {
    ${tw`mb-14`}
  }

  li {
    ${tw`relative mb-4 pl-6`}

    &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99972 0.5L9.11376 2.27803L10.7783 0.982535L11.2078 3.02857L13.2214 2.37164L12.9142 4.43885L15.0353 4.50014L14.0282 6.33946L16 7.11062L14.4152 8.50028L16 9.88938L14.0282 10.6611L15.0353 12.4999L12.9142 12.5612L13.2214 14.6284L11.2078 13.9714L10.7783 16.0175L9.11376 14.722L7.99972 16.5L6.88624 14.722L5.22168 16.0175L4.79225 13.9714L2.7786 14.6284L3.08582 12.5612L0.964673 12.4999L1.97178 10.6611L0 9.88938L1.58478 8.50028L0 7.11062L1.97178 6.33946L0.964673 4.50014L3.08582 4.43885L2.7786 2.37164L4.79225 3.02857L5.22168 0.982535L6.88624 2.27803L7.99972 0.5Z' fill='%2376F700'/%3E%3C/svg%3E");
    }
  }
`

const WpAcfTextModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfModuleTextModule
  return (
    <TextContainer>
      <Text>{parse(blockData.text)}</Text>
    </TextContainer>
  )
}

WpAcfTextModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfTextModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfTextModuleBlock
