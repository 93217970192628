import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import { motion, AnimatePresence } from "framer-motion"

const TextContainer = styled.div`
  ${tw`max-w-screen-lg`}
`

const Headline = styled.div`
  ${tw`font-black text-2xl lg:text-4xl mb-4`}
`

const Text = styled.div`
  ${tw`font-medium leading-[1.5] text-sm`}

  h3 {
    ${tw`font-black leading-[1.5] text-4xl mb-4`}
  }

  p {
    ${tw`mb-4`}
  }
`

const Karten = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 gap-8 py-6`}
`

const KarteContent = styled(motion.div)`
  ${tw`border-[#E6FF00] border-2 py-6 px-4`}
`

const KartenCta = styled(motion.div)`
  ${tw`flex justify-center`}
`

const KarteHeadline = styled.div`
  ${tw`flex flex-row items-center gap-2 mb-3 font-bold text-lg lg:text-2xl`}
`

const KarteDescription = styled.div`
  ${tw`font-medium leading-[1.5] text-sm`}
`

const WpAcfBlogKartenModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfBlockBlogKartenModule
  const [showAll, setShowAll] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.matchMedia("(max-width: 768px)").matches)

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const displayedCards = showAll || !isMobile ? blockData.karten : blockData.karten.slice(0, 3)

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <TextContainer>
      <Headline>{parse(blockData.headline)}</Headline>
      <Text>{parse(blockData.description)}</Text>
      <Karten>
        <AnimatePresence>
          {displayedCards.map((karte, index) => (
            <KarteContent key={index} initial="hidden" animate="visible" exit="hidden" variants={cardVariants} transition={{ duration: 0.3, ease: "easeOut", delay: index * 0.1 }}>
              <KarteHeadline>
                <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1954_525)">
                    <path
                      d="M14.9995 0L17.0883 3.3338L20.2093 0.904754L21.0145 4.74108L24.7901 3.50932L24.2141 7.38534L28.1912 7.50026L26.3029 10.949L30 12.3949L27.0285 15.0005L30 17.6051L26.3029 19.0521L28.1912 22.4997L24.2141 22.6147L24.7901 26.4907L21.0145 25.2589L20.2093 29.0952L17.0883 26.6662L14.9995 30L12.9117 26.6662L9.79066 29.0952L8.98547 25.2589L5.20987 26.4907L5.78592 22.6147L1.80876 22.4997L3.69709 19.0521L0 17.6051L2.97146 15.0005L0 12.3949L3.69709 10.949L1.80876 7.50026L5.78592 7.38534L5.20987 3.50932L8.98547 4.74108L9.79066 0.904754L12.9117 3.3338L14.9995 0Z"
                      fill="#0000FF"
                    />
                  </g>
                  <path
                    d="M30.3544 5.33956L30.7054 4.98689L30.3544 4.63422L28.1909 2.45983L27.8364 2.10362L27.482 2.45983L13.5067 16.5049L10.0179 12.9987L9.66348 12.6425L9.30905 12.9987L7.14555 15.1732L6.79466 15.5259L7.14557 15.8785L13.1524 21.9152L13.5068 22.2714L13.8612 21.9152L30.3544 5.33956Z"
                    fill="white"
                    stroke="#0000FF"
                  />
                  <defs>
                    <clipPath id="clip0_1954_525">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {karte.headline}
              </KarteHeadline>
              <KarteDescription>{parse(karte.text)}</KarteDescription>
            </KarteContent>
          ))}
        </AnimatePresence>
        {isMobile && (
          <KartenCta layout initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, ease: "easeOut" }} onClick={() => setShowAll(prev => !prev)}>
            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M27.5019 0L29.7724 1.53955L32.2761 0.415989L34.2472 2.32876L36.9064 1.66007L38.5159 3.87997L41.249 3.68559L42.4503 6.15042L45.1757 6.43423L45.9299 9.07401L48.5658 9.82434L48.8496 12.5535L51.3183 13.751L51.12 16.4879L53.3438 18.0936L52.6751 20.7567L54.584 22.7239L53.4643 25.2276L55 27.5019L53.4643 29.7724L54.584 32.2761L52.6751 34.2472L53.3438 36.9064L51.12 38.5159L51.3183 41.249L48.8496 42.4503L48.5658 45.1757L45.9299 45.9299L45.1757 48.5658L42.4503 48.8496L41.249 51.3183L38.5159 51.12L36.9064 53.3438L34.2472 52.6751L32.2761 54.584L29.7724 53.4643L27.5019 55L25.2276 53.4643L22.7239 54.584L20.7567 52.6751L18.0936 53.3438L16.4879 51.12L13.751 51.3183L12.5535 48.8496L9.82434 48.5658L9.07401 45.9299L6.43423 45.1757L6.15042 42.4503L3.68559 41.249L3.87997 38.5159L1.66007 36.9064L2.32876 34.2472L0.415989 32.2761L1.53955 29.7724L0 27.5019L1.53955 25.2276L0.415989 22.7239L2.32876 20.7567L1.66007 18.0936L3.87997 16.4879L3.68559 13.751L6.15042 12.5535L6.43423 9.82434L9.07401 9.07401L9.82434 6.43423L12.5535 6.15042L13.751 3.68559L16.4879 3.87997L18.0936 1.66007L20.7567 2.32876L22.7239 0.415989L25.2276 1.53955L27.5019 0Z"
                fill="#FF00A1"
              />
              <rect x="25.4443" y="16" width="5.11111" height="23" fill="white" />
              <rect x="39.5" y="24.9445" width="5.11111" height="23" transform="rotate(90 39.5 24.9445)" fill="white" />
            </svg>
          </KartenCta>
        )}
      </Karten>
    </TextContainer>
  )
}

WpAcfBlogKartenModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfBlogKartenModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfBlogKartenModuleBlock
